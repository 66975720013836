import React from "react";

import ServerGuide, {
    GeneralCancelCommandNotes,
    GeneralUsingNativeApiNotes,
    GeneralNativeConnectionHandles,
    GeneralNativeCommandHandles,
    GenericPosNotSupportedErrorNotes
} from "../../components/server-guide";
import {options} from "../../data/odbc";
import {Callout} from "../../components/alert";
import {ApiLink} from "../../components/link";

const ConnectApiNotes = () => (
    <Callout heading="Minimum Version">
        SQLAPI++ library requires ODBC version 3.x or higher.
    </Callout>
);
const sDBString = () => (
    <p>
        Any valid ODBC connection string
    </p>
);

const UserID = () => (<p>A string containing a user name to use when establishing the connection.</p>);

const OutputParamNotes = () => (
    <>
        <p>
            In ODBC processing output parameters depends on a driver.
        </p>
        <p>
            If DBMS supports returning output parameters then SQLAPI++ stores the returned
            values of output parameters in the {' '}<ApiLink>SAParam</ApiLink>{' '} objects bound to those parameters.
            On some servers these returned values are not guaranteed to be available until all
            results returned by the procedure have been fetched
            (using {' '}<ApiLink>SACommand::FetchNext</ApiLink>{' '} method).
        </p>
        <p>
            See ODBC driver documentation on output parameters availability on different drivers/servers.
        </p>
        <p>
            SQLAPI++ Library automatically creates {' '}<ApiLink>SAParam</ApiLink>{' '} object to represent function
            return value. You can refer to this {' '}<ApiLink>SAParam</ApiLink>{' '} object using SQLAPI++ predefined
            name {' '}<code>"RETURN_VALUE"</code>.
        </p>
    </>
);

const Page = () => (
    <ServerGuide server="ODBC"
                 connectApiNotes={ConnectApiNotes}
                 sDBString={sDBString}
                 sUserID={UserID}
                 outputParamNotes={OutputParamNotes}
                 cancelCommandNotes={() => <GeneralCancelCommandNotes server="ODBC"
                                                                      nativeCommand="SQLCancel"/>}
                 options={options}
                 usingNativeApiNotes={() => <GeneralUsingNativeApiNotes server="ODBC"
                                                                        cppHeader="odbcAPI.h"
                                                                        apiClass="odbcAPI"/>}
                 nativeConnectionHandles={() => <GeneralNativeConnectionHandles server="ODBC"
                                                                                handleClass="odbcConnectionHandles"
                                                                                cppHeader="odbcAPI.h"/>}
                 nativeCommandHandles={() => <GeneralNativeCommandHandles server="ODBC"
                                                                          handleClass="odbcCommandHandles"
                                                                          cppHeader="odbcAPI.h"/>}
                 errorNotes={() => <GenericPosNotSupportedErrorNotes server="ODBC"/>}
    />
);

export default Page;
